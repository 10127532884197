<template>
  <div>
    <el-input :placeholder="placeholder" v-model="password" :type="type" autocomplete="new-password">
      <template #suffix>
        <i class="iconfont icon-a-kecheng1" v-if="hidePwd" @click="toggleInputType"></i>
        <i class="iconfont icon-a-kecheng2" v-else @click="toggleInputType"></i>
      </template>
    </el-input>
  </div>
</template>

<script>
  export default {
    props: {
      pwd: {
        type: String,
        default: ''
      },
      placeholder: {
        type: String,
        default: '请输入密码'
      }
    },
    data() {
      return {
        password: this.pwd,
        type: 'password',
        hidePwd: true
      }
    },
    methods: {
      toggleInputType() {
        this.hidePwd = !this.hidePwd;
      }
    },
    watch: {
      pwd(val) {
        this.password = val
      },
      password(val) {
        this.$emit('update:pwd', val);
      },
      hidePwd(val) {
        if(val) {
          this.type = 'password'
        } else {
          this.type = 'text'
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .iconfont {
    cursor: pointer;
    line-height:45px;
    margin-right:5px;
    color:#666;
    font-size:14px;
    color:#999;
  }
</style>
