<template>
  <div>
    <!-- 修改密码 -->
    <el-dialog
        title="修改密码" @closed="closeRestPwdModal"
        v-model="resetPwdModal.visible"
        width="800px">
        <el-alert v-if="resetPwdModal.error" type="error" show-icon :closable="false" style="margin-bottom:20px;">{{ resetPwdModal.error }}</el-alert>
        <el-form :model="resetPwdModal.form" :rules="resetPwdModal.rules" label-width="100px" ref="resetPwdForm">
          <el-form-item label="当前密码" prop="oldPwd">
<!--            <el-input v-model="resetPwdModal.form.oldPwd" show-password></el-input>-->
            <PwdInput v-model:pwd="resetPwdModal.form.oldPwd" v-if="resetPwdModal.visible" />
          </el-form-item>
          <el-form-item label="新设密码" prop="pass">
<!--            <el-input v-model="resetPwdModal.form.pass" show-password autocomplete="new-password"></el-input>-->
            <PwdInput v-model:pwd="resetPwdModal.form.pass" v-if="resetPwdModal.visible" />
          </el-form-item>
          <el-form-item label="确认密码" prop="checkPass">
<!--            <el-input v-model="resetPwdModal.form.checkPass" show-password autocomplete="new-password"></el-input>-->
            <PwdInput v-model:pwd="resetPwdModal.form.checkPass" v-if="resetPwdModal.visible" />
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="closeRestPwdModal">取消</el-button>
            <el-button type="primary" @click="submitForm('resetPwdForm')">确定</el-button>
          </span>
        </template>
    </el-dialog>
  </div>
</template>

<script>
  import PwdInput from 'components/common/PwdInput'
  import { checkPassword } from 'utils/common.js'
  import { UpdatePWD } from 'api/user/auth/login'
  export default {
    components: {
      PwdInput
    },
    props: {
      visible: {
        type: Boolean,
        default: false
      }
    },
    data() {
      let validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else {
          if (this.resetPwdModal.form.checkPass !== '') {
            this.$refs.resetPwdForm.validateField('checkPass');
          }
          callback();
        }
      };

      let validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.resetPwdModal.form.pass) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };

      return {
        resetPwdModal: {
          visible: this.visible,
          error: '',
          form: {
            oldPwd: '',
            pass: '',
            checkPass: ''
          },
          rules: {
            pass: [
              { required: true, validator: checkPassword, trigger: 'blur' },
              { required: true, validator: validatePass, trigger: 'blur' }
            ],
            checkPass: [
              { required: true, validator: checkPassword, trigger: 'blur' },
              { required: true, validator: validatePass2, trigger: 'blur' }
            ],
            oldPwd: [
              { required: true, message: '请输入当前密码', trigger: 'blur' }
            ]
          }
        }
      }
    },
    methods: {
      submitForm(formName) {
          this.$refs[formName].validate((valid) => {
            if (valid) {
              UpdatePWD(this.resetPwdModal.form)
                .then((res) => {
                  if (res.hasOwnProperty("error") && res.error === 'Not Login') {
                      this.$emit('update:loginVisible', true);
                      this.closeRestPwdModal();
                  }
                  if (!res.result) {
                    this.closeRestPwdModal()
                  } else {
                    this.resetPwdModal.error = res.msg;
                  }
                }).catch((err) => {
                this.$alert('服务异常，请稍后再试', '提示', {
                      confirmButtonText: '确定',
                });
              })
            } else {
              return false;
            }
          });
      },
      closeRestPwdModal() {
        this.resetPwdModal.error = '';
        this.resetPwdModal.visible = false;
        this.$emit('update:visible', this.resetPwdModal.visible);
        this.$refs.resetPwdForm.resetFields();
      }
    },
    watch: {
      visible: {
        handler(val) {
          this.resetPwdModal.visible = val;
        },
        deep: true
      }
    }
  }
</script>
