<template>
  <div>
    <div class="logo">
      <img :src="require('/src/assets/img/logo/fnedu.png')" @click="goPath('/home')" />
      <a href="https://www.sdnlab.com/" target="_blank"><img :src="require('/src/assets/img/logo/sdnlab.png')" /></a>
    </div>
    <div class="login_wrap"
         :v-loading="oauth_loading"
         :element-loading-text="login_loading_msg">
      <div class="banner"></div>
      <transition name="slide-fade" mode="out-in">
        <div class="login_form" v-show="showLogin">
          <div class="content_wrap" v-if="loginModal.visible">
            <h3 class="clearfix">用户登录<el-button type="text" @click="openRegister()" class="pull-right">去注册 >></el-button></h3>
            <el-alert v-if="loginModal.login_error" type="error" show-icon :closable="false" style="margin-bottom:20px;">{{ loginModal.login_error }}</el-alert>
            <el-form :model="loginModal.loginForm" :rules="rules" ref="loginForm">
              <el-form-item prop="tel">
                <el-input placeholder="请输入手机号/用户名/邮箱" v-model="loginModal.loginForm.tel"></el-input>
              </el-form-item>
              <el-form-item prop="pwd">
<!--                <el-input placeholder="请输入密码" v-model="loginModal.loginForm.pwd" show-password autocomplete="new-password"></el-input>-->
                <PwdInput v-model:pwd="loginModal.loginForm.pwd" />
              </el-form-item>
              <el-form-item prop="verify">
                <PuzzleVerify :width="380" @status="getLoginStatus" @result="getLoginResult" v-if="loginModal.visible"></PuzzleVerify>
              </el-form-item>
              <el-form-item class="btns">
                <el-button type="primary" style="width:100%;" @click="submitLoginForm()">立即登录</el-button>
                <el-checkbox v-model="loginModal.loginForm.autoLogin">下次自动登录</el-checkbox>
                <el-button type="text" class="pull-right" @click="showForgetPwd">忘记密码？</el-button>
              </el-form-item>
            </el-form>
            <div class="sep"><span>第三方账户登录</span></div>
            <div class="third_login">
              <el-button type="text" @click="startThirdPartyLogin('qq')" class="iconfont icon-111_huaban1" style="color:#18acfc;"></el-button>
              <el-button v-if="false" type="text" @click="startThirdPartyLogin('weixin')" class="iconfont icon-111-02" style="color:#00bb29;"></el-button>
              <el-button type="text" @click="startThirdPartyLogin('weibo')" class="iconfont icon-111-03" style="color:#d72b2b;"></el-button>
              <el-button type="text" @click="startThirdPartyLogin('baidu')" class="iconfont icon-111-04" style="color:#306cff;"></el-button>
              <el-button type="text" @click="startThirdPartyLogin('github')" class="iconfont icon-111-05" style="color:#1f76b6;"></el-button>
            </div>
          </div>
          <div class="content_wrap" v-else>
            <h3>用户注册<el-button type="text" @click="openLogin()" class="pull-right">立即登录 >></el-button></h3>
            <el-alert v-if="loginModal.register_error" type="error" show-icon :closable="false" style="margin-bottom:20px;">{{ loginModal.register_error }}</el-alert>
            <el-form :model="loginModal.registerForm" :rules="rules" ref="registerForm">
              <el-form-item prop="register_tel">
                <el-input placeholder="请输入手机号" class="input_tel" v-model="loginModal.registerForm.register_tel">
                  <template #prefix>
                    CN + 86
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item prop="register_pwd">
<!--                <el-input placeholder="请输入密码" v-model="loginModal.registerForm.register_pwd" show-password autocomplete="new-password"></el-input>-->
                <PwdInput v-model:pwd="loginModal.registerForm.register_pwd" />
              </el-form-item>
              <el-form-item prop="yzm" v-if="!loginModal.isVerify">
                <div class="get_yzm">
                  <el-input :placeholder="loginModal.registerForm.yzm_placeholder"
                            v-model="loginModal.registerForm.yzm"></el-input>
                  <el-button type="text" @click="getSms()" v-if="!loginModal.isGetYzm">获取验证码</el-button>
                  <span v-else>{{ loginModal.countdown }}</span>
                </div>
              </el-form-item>
              <el-form-item prop="verify" v-else>
                <PuzzleVerify :width="380" @status="getRegisterStatus" @result="getRegisterResult"></PuzzleVerify>
              </el-form-item>
              <el-form-item class="btns" prop="agree">
                <el-button type="primary" style="width:100%;" @click="submitRegisterForm()">立即注册</el-button>
                <el-checkbox v-model="loginModal.registerForm.agree">同意<router-link to="/agreement" target="_blank">《未来网络学堂用户注册使用协议》</router-link> </el-checkbox>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </transition>
    </div>

    <!-- 忘记密码 -->
    <el-dialog
      title="忘记密码"
      v-model="forgetPwdModal.visible"
      @closed="closeForgetPwdModal"
      width="500px" custom-class="forget_pwd_modal">
      <div v-if="forgetPwdModal.visible">
        <el-alert v-if="forgetPwdModal.error" type="error" show-icon :closable="false" style="margin-bottom:20px;">{{ forgetPwdModal.error }}</el-alert>
        <el-form :model="forgetPwdModal.form" :rules="rules" ref="forgetPwdForm">
          <el-form-item prop="forget_tel">
            <el-input placeholder="请输入手机号" class="input_tel" v-model="forgetPwdModal.form.forget_tel">
              <template #prefix>
                CN + 86
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="pwd">
<!--            <el-input placeholder="请输入新密码" v-model="forgetPwdModal.form.pwd" show-password autocomplete="new-password"></el-input>-->
            <PwdInput v-model:pwd="forgetPwdModal.form.pwd" placeholder="请输入新密码" />
          </el-form-item>
          <el-form-item prop="yzm" v-if="!forgetPwdModal.isVerify">
            <div class="get_yzm">
              <el-input :placeholder="forgetPwdModal.form.yzm_placeholder"
                        v-model="forgetPwdModal.form.yzm"></el-input>
              <el-button type="text" @click="getForgetSms()" v-if="!forgetPwdModal.isGetYzm">获取验证码</el-button>
              <span v-else>{{ forgetPwdModal.countdown }}</span>
            </div>
          </el-form-item>
          <el-form-item prop="verify" v-else>
            <PuzzleVerify :width="460" :height="200" @status="getForgetPwdStatus" @result="getForgetPwdResult"></PuzzleVerify>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closeForgetPwdModal()">取 消</el-button>
          <el-button type="primary" @click="submitForgetForm()">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 补充手机号 -->
    <el-dialog
        title="补充手机号码"
        v-model="addCellPhoneModal.visible" @closed="closeAddPhoneModal"
        width="500px" custom-class="forget_pwd_modal">
        <div v-if="addCellPhoneModal.visible">
          <el-alert v-if="addCellPhoneModal.error" type="error" show-icon :closable="false" style="margin-bottom:20px;">{{ addCellPhoneModal.error }}</el-alert>
          <el-form :model="addCellPhoneModal.form" :rules="rules" ref="addCellPhoneForm">
            <el-form-item prop="addtel">
              <el-input placeholder="请输入手机号" class="input_tel" v-model="addCellPhoneModal.form.addtel">
                <template #prefix>
                  CN + 86
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="yzm" v-if="!addCellPhoneModal.isVerify">
              <div class="get_yzm">
                <el-input :placeholder="addCellPhoneModal.form.yzm_placeholder"
                          v-model="addCellPhoneModal.form.yzm"></el-input>
                <el-button type="text" @click="getAddSms()" v-if="!addCellPhoneModal.isGetYzm">获取验证码</el-button>
                <span v-else>{{ addCellPhoneModal.countdown }}</span>
              </div>
            </el-form-item>
            <el-form-item prop="verify" v-else>
              <PuzzleVerify :width="460" :height="200" @status="getAddPhoneStatus" @result="getAddPhoneResult"></PuzzleVerify>
            </el-form-item>
          </el-form>
        </div>
        <template #footer>
            <span class="dialog-footer">
              <el-button @click="addCellPhoneModal.visible = false">取 消</el-button>
              <el-button type="primary" @click="submitAddPhoneForm()">确 定</el-button>
            </span>
        </template>
    </el-dialog>
  </div>
</template>

<script>
  import PuzzleVerify from 'components/common/PuzzleVerify'
  import ResetPwdModal from 'components/ResetPwdModal.vue'
  import PwdInput from 'components/common/PwdInput'
  import { checkPhone, checkPassword, routePathHandler } from 'utils/common.js'
  import { checkPhoneExist, SendSms, RegisterUser } from 'api/user/auth/register'
  import { GetUserState, LoginUser, LogOutUser, ThirdPartyLogin, AddCellPhone, ForgetPWD, updateUserLoginState, oauthAuthLogin } from 'api/user/auth/login'
  import { showMessage } from 'api/base/message'
  export default {
    components: {
      PuzzleVerify,
      ResetPwdModal,
      PwdInput
    },
    data() {
      let checkPhoneExistRule = (rule, value, callback) => {
        checkPhoneExist(value).then((res) => {
          if (!res.result) {
            callback()
          } else {
            return callback(new Error('手机号码已经被注册'))
          }
        }).catch(err => {
          return callback(new Error('服务异常，请稍后再试'))
        });
      }

      let checkPhoneNotExistRule = (rule, value, callback) => {
        checkPhoneExist(value).then((res) => {
          if (!res.result) {
            return callback(new Error('该手机号码不存在'))
          } else {
            callback()
          }
        }).catch(err => {
          return callback(new Error('服务异常，请稍后再试'))
        });
      }
      return {
        showLogin: false,
        loginModal: {
          visible: true,
          login_error: '',
          isGetYzm: false,
          isVerify: false,
          countdown: '等待 60 s',
          loginForm: {
            tel: '',
            pwd: '',
            verify: '',
            autoLogin: false,
            oauth_token: ''
          },
          register_error: '',
          registerForm: {
            register_tel: '',
            register_pwd: '',
            yzm: '',
            yzm_placeholder: '请输入短信验证码',
            agree: [],
            oauth_token: ''
          },
        },
        forgetPwdModal: {
          error: '',
          visible: false,
          isGetYzm: false,
          isVerify: false,
          countdown: '等待 60 s',
          countdowntime: 60,
          form: {
            forget_tel: '',
            yzm: '',
            yzm_placeholder: '请输入短信验证码',
            pwd: '',
            verify: '',
          }
        },
        addCellPhoneModal: {
          error: '',
          visible: false,
          isGetYzm: false,
          isVerify: false,
          countdown: '等待 60 s',
          form: {
            addtel: '',
            yzm: '',
            yzm_placeholder: '请输入短信验证码',
            verify: '',
            token: '',
            target: '',
            user_id: '',
            oauth_token: ''
          }
        },
        rules: {
          register_tel: [
            { required: true, validator: checkPhone, trigger: 'blur' },
            { required: true, validator: checkPhoneExistRule, trigger: 'blur' }
          ],
          register_pwd: [
            { required: true, message: '请输入密码', trigger: 'blur' },
            { validator: checkPassword, trigger: 'blur' }
          ],
          yzm: [
            { required: true, message: '请输入验证码', trigger: 'blur' },
          ],
          verify: [
            { required: true, message: '请进行拼图验证', trigger: 'blur' },
          ],
          agree: [
            { required: true, type:'array', message: '请同意协议', trigger: 'blur' },
          ],

          tel: [
            { required: true, message: '请填写账号', trigger: 'blur' },
          ],
          pwd: [
            { required: true, message: '请输入密码', trigger: 'blur' },
            { validator: checkPassword, trigger: 'blur' }
          ],

          addtel: [
            { required: true, validator: checkPhone, trigger: 'blur' },
          ],

          forget_tel: [
            { required: true, validator: checkPhone, trigger: 'blur' },
            { required: true, validator: checkPhoneNotExistRule, trigger: 'blur' }
          ]
        },
        user:{
          is_authenticated: false,
          user_id: null,
          user_name: null,
          cellphone: null,
        },
        resetModalVisible: false,
        cartItemCount: '',
        checkLoginTime: null,
        oauth_mode: false,
        oauth_loading: true,
        login_loading_msg: '',
      }
    },
    created() {
      let oauth_token = this.$route.query.oauth_token;
      if (oauth_token) {
        this.loginModal.loginForm.oauth_token = oauth_token;
        this.loginModal.registerForm.oauth_token = oauth_token;
        this.addCellPhoneModal.form.oauth_token = oauth_token;
        this.oauth_mode = true;
      }

      let oauth_register = this.$route.query.oauth_register;

      if (oauth_register) {
          this.showLogin = false;
          this.loginModal.login_error = "";
          this.loginModal.isVerify = false;
          this.changeFormAnimation(that => {
            that.loginModal.visible = false;
          });
      } else {
          this.load_user_status()
      }

    },
    mounted() {
      this.changeFormAnimation();
      window.newThirdBindCellphone = this.newThirdBindCellphone;
      window.showLoginMessage = this.showThirdBindMessage;
    },
    methods: {
      routePathHandler,
      async load_user_status(){

        const that = this;

        await GetUserState({}).then((res) => {
            if (res.id) {

              if (this.oauth_mode) {
                that.oauth_auto_login();
              } else {
                this.goNext();
              }

            } else {
              this.checkLoginTime = setInterval(this.checkUserHasLogin, 10000);
            }
        }).catch(error => {
        });
      },

      async oauth_auto_login(){

        this.oauth_loading = true;
        this.login_loading_msg = '正在自动登录中 ...';

        await oauthAuthLogin(this.loginModal.loginForm.oauth_token).then(result => {

          if (result.succeed) {
            window.location.href = result.notify_session_url + "&refer_url=" + this.$route.query.url;
          } else {
            //showMessage('自动登录失败,请手动进行登录', 'error');
          }
        }).catch(err => {
        });
      },

      checkUserHasLogin() {
        let that = this;
        updateUserLoginState().then((res) => {
          if (res.hasOwnProperty("result") && res.result === 0) {
            clearInterval(that.checkLoginTime)
            console.log("check 000000000000000000000000")
            that.goNext();
          }
        }).catch(error => {

        });
      },

      changeFormAnimation(callback) {
        const that = this;
        setTimeout(() => {
          if(callback) {
            callback(that);
          }
          that.showLogin = true;
        }, 500);
      },
      //登录
      getLoginStatus(val) {
        if(val) {
          this.loginModal.loginForm.verify = 'success';
        } else {
          this.loginModal.loginForm.verify = '';
        }
      },
      getLoginResult(val) {
        if(val === 'success') {
          this.loginModal.isVerify = true;
          this.$refs.loginForm.clearValidate('verify');
        }
      },
      submitLoginForm() {
        this.loginModal.login_error = '';
        this.$refs.loginForm.validate((valid) => {
          if (valid) {
            LoginUser(this.loginModal.loginForm)
              .then((res) => {
                if (!res.result) {
                  if (res.notify_session_url) {
                    window.location.href = res.notify_session_url + "&refer_url=" + this.$route.query.url;
                  } else {
                    this.goNext();
                  }
                } else {
                  this.loginModal.login_error = res.msg
                }
              }).catch((err) => {
              this.$alert('服务异常，请稍后再试', '提示', {
                    confirmButtonText: '确定',
              });
            })
          } else {
            return false;
          }
        });
      },
      openRegister() {
        this.showLogin = false;
        this.loginModal.login_error = "";
        this.$refs.loginForm.resetFields();
        this.loginModal.isVerify = false;
        this.changeFormAnimation(that => {
          that.loginModal.visible = false;
        });
      },
      // 第三方登录
      startThirdPartyLogin(type) {
        updateUserLoginState().then((res) => {
          if (res.hasOwnProperty("error") && res.error === 'Not Login State') {
            let target = "";
            if (this.$route.query.oauth_token) {
              let url = this.$route.query.url ? this.$route.query.url : ""
              target = url + "&oauth_token=" + this.$route.query.oauth_token;
            } else {
              target = this.$route.query.next;
            }
            ThirdPartyLogin(type, target)
          } else {
            this.goNext();
          }
        }).catch(error => {
        });
      },
      newThirdBindCellphone(token, target_url) {
        this.addCellPhoneModal.form.token = token;
        this.addCellPhoneModal.form.target = target_url;
        this.loginModal.visible = false;
        this.addCellPhoneModal.visible = true;
      },
      showThirdBindMessage(message) {
        this.$alert(message, '提示', {
              confirmButtonText: '确定',
        });
      },
      getAddSms() {
        this.$refs.addCellPhoneForm.validateField(['addtel'], (valid) => {
          if (!valid) {
            this.addCellPhoneModal.isVerify = true;
          }
        });
      },
      getAddPhoneStatus(val) {
        // 判断图形校验是否成功
        if(val) {
          this.addCellPhoneModal.form.verify = 'success';
        } else {
          this.addCellPhoneModal.form.verify = '';
        }
      },
      getAddPhoneResult(val) {
        if(val === 'success') {
          // 图形校验成功
          this.addCellPhoneModal.isVerify = false;
          SendSms(this.addCellPhoneModal.form.addtel).then((res) => {
            if (!res.result){
              this.addCellPhoneModal.form.yzm_placeholder = `请输入短信验证码 (序列号：${res.serial})`;
              this.getAddPhoneYzm();
            } else {
              this.$alert(res.msg, '提示', {
                    confirmButtonText: '确定',
              });
            }
          }).catch((error) => {
            this.$alert('服务异常，请稍后再试', '提示', {
                  confirmButtonText: '确定',
            });
          });
        }
      },
      getAddPhoneYzm(surplus_time) {
        // 展示等待时间
        this.addCellPhoneModal.isGetYzm = true;
        let time = surplus_time ? surplus_time : 60;
        const countdown = setInterval(() => {
          if(time > 0) {
            time --;
            this.addCellPhoneModal.countdown = `等待 ${ time } s`
          } else {
            clearInterval(countdown);
            this.addCellPhoneModal.isGetYzm = false;
            this.addCellPhoneModal.countdown = `等待 60 s`;
          }
        }, 1000)
      },
      submitAddPhoneForm() {
        this.$refs.addCellPhoneForm.validate((valid) => {
          if (valid) {
            AddCellPhone(this.addCellPhoneModal.form)
              .then((res) => {
                if (!res.result) {
                  if (res.notify_session_url) {
                      window.location.href = res.notify_session_url + "&refer_url=" + this.$route.query.url;
                    } else {
                      // this.loginModal.visible = false;
                      if (this.user.is_authenticated) {
                          this.user.cellphone = true;
                          this.addCellPhoneModal.visible = false;
                      } else {
                        this.goNext();
                      }
                    }
                } else {
                  this.addCellPhoneModal.error = res.msg;
                }
              }).catch((err) => {
              this.$alert('服务异常，请稍后再试', '提示', {
                    confirmButtonText: '确定',
              });
            })
          } else {
            return false;
          }
        });
      },
      closeAddPhoneModal() {
        this.addCellPhoneModal.error = '';
        this.$refs.addCellPhoneForm.resetFields();
        if (this.user.is_authenticated && !this.user.cellphone) {
          this.$router.go(0);
        }
      },
      closeLoginModal() {
        this.loginModal.login_error = "";
        this.loginModal.register_error = "";
        this.loginModal.isVerify = false;
        if (this.loginModal.isLogin) {
          this.$refs.loginForm.resetFields();
        } else {
          this.$refs.registerForm.resetFields();
        }
      },

      //注册
      getSms() {
        // 点击获取验证码，先弹出图形校验
        let validate = 2;
        this.$refs.registerForm.validateField(['register_tel', 'register_pwd'], (valid) => {
          if (!valid) {
            validate -= 1
          }
          if (validate === 0) {
            this.loginModal.isVerify = true;
          }
        });
      },
      closeForgetPwdModal() {
        this.forgetPwdModal.error = '';
        this.forgetPwdModal.visible = false;
        this.forgetPwdModal.isGetYzm = false;
        this.forgetPwdModal.isVerify = false;
        this.loginModal.visible = true;
        this.$refs.forgetPwdForm.resetFields();
      },
      submitRegisterForm() {
        this.loginModal.register_error = '';
        this.$refs.registerForm.validate((valid) => {
          if (valid) {
            RegisterUser(this.loginModal.registerForm)
              .then((res) => {
                if (!res.result) {
                  if (!res.result) {
                    if (res.notify_session_url) {
                      window.location.href = res.notify_session_url + "&refer_url=" + this.$route.query.url;
                    } else {
                      this.goNext();
                    }
                  } else {
                    this.loginModal.login_error = res.msg
                  }
                } else {
                  this.loginModal.register_error = res.msg
                }
              }).catch((err) => {
              this.$alert('服务异常，请稍后再试', '提示', {
                    confirmButtonText: '确定',
              });
            })
          } else {
            return false;
          }
        });
      },
      getRegisterStatus(val) {
        // 判断图形校验是否成功
        if(val) {
          this.loginModal.registerForm.verify = 'success';
        } else {
          this.loginModal.registerForm.verify = '';
        }
      },
      getRegisterResult(val) {
        if(val === 'success') {
          // 图形校验成功
          this.loginModal.isVerify = false;
          SendSms(this.loginModal.registerForm.register_tel).then((res) => {
            if (!res.result){
              this.loginModal.registerForm.yzm_placeholder = `请输入短信验证码 (序列号：${res.serial})`;
              this.getYzm();
            } else {
              this.$alert(res.msg, '提示', {
                    confirmButtonText: '确定',
              });
            }
          }).catch((error) => {
            this.$alert('服务异常，请稍后再试', '提示', {
                  confirmButtonText: '确定',
            });
          });
        }
      },
      openLogin() {
        this.showLogin = false;
        this.loginModal.register_error = "";
        this.$refs.registerForm.resetFields();
        this.loginModal.isLogin = true;
        this.loginModal.isVerify = false
        this.changeFormAnimation(that => {
          that.loginModal.visible = true;
        });
      },
      // 忘记密码
      showForgetPwd() {
        this.forgetPwdModal.visible = true;
        this.loginModal.visible = false;
      },
      getForgetSms() {
        let validate = 2;
        this.$refs.forgetPwdForm.validateField(['forget_tel', 'pwd'], (valid) => {
          if (!valid) {
            validate -= 1
          }
          if (validate === 0) {
            this.forgetPwdModal.isVerify = true;
          }
        });
      },
      getForgetPwdStatus(val) {
        // 判断图形校验是否成功
        if(val) {
          this.forgetPwdModal.form.verify = 'success';
        } else {
          this.forgetPwdModal.form.verify = '';
        }
      },
      getForgetPwdResult(val) {
        if(val === 'success') {
          // 图形校验成功
          this.forgetPwdModal.isVerify = false;
          SendSms(this.forgetPwdModal.form.forget_tel).then((res) => {
            if (!res.result){
              this.forgetPwdModal.form.yzm_placeholder = `请输入短信验证码 (序列号：${res.serial})`;
              this.getForgetYzm(this.forgetPwdModal.countdowntime);
            } else {
              this.$alert(res.msg, '提示', {
                    confirmButtonText: '确定',
              });
            }
          }).catch((error) => {
            this.$alert('服务异常，请稍后再试', '提示', {
                  confirmButtonText: '确定',
            });
          });
        }
      },
      getForgetYzm(surplus_time) {
        // 展示等待时间
        this.forgetPwdModal.isGetYzm = true;
        let time = surplus_time ? surplus_time : 60;
        if (time < 60) { return }
        const countdown = setInterval(() => {
          if(time > 0) {
            time --;
            this.forgetPwdModal.countdown = `等待 ${ time } s`
            this.forgetPwdModal.countdowntime = `${ time }`
          } else {
            clearInterval(countdown);
            this.forgetPwdModal.isGetYzm = false;
            this.forgetPwdModal.countdown = `等待 60 s`;
            this.forgetPwdModal.countdowntime = 60
          }
        }, 1000)
      },
      submitForgetForm() {
        this.$refs.forgetPwdForm.validate((valid) => {
          if (valid) {
            ForgetPWD(this.forgetPwdModal.form)
              .then((res) => {
                if (!res.result) {
                  this.forgetPwdModal.visible = false;
                } else {
                  this.forgetPwdModal.error = res.msg;
                }
              }).catch((err) => {
              this.$alert('服务异常，请稍后再试', '提示', {
                    confirmButtonText: '确定',
              });
            })
          } else {
            return false;
          }
        });
      },
      //获取验证码
      getYzm(surplus_time) {
        // 展示等待时间
        this.loginModal.isGetYzm = true;
        let time = surplus_time ? surplus_time : 60;
        const countdown = setInterval(() => {
          if(time > 0) {
            time --;
            this.loginModal.countdown = `等待 ${ time } s`
          } else {
            clearInterval(countdown);
            this.loginModal.isGetYzm = false;
            this.loginModal.countdown = `等待 60 s`;
          }
        }, 1000)
      },
      goPath(path) {
        this.$router.push(path)
      },
      goNext() {
        if (this.$route.name === "Login") {
          let next_url = this.$route.query.next ? this.$route.query.next : "/home";
          if (next_url === "" || next_url === "/") {
            next_url = "/home";
          }
          this.$router.push(next_url);
        }
      }
    }
  }
</script>

<style lang="scss">
  #chatBtn {display:none !important;}
</style>
<style lang="scss" scoped>
  .logo {
    position: absolute;
    left:50px;
    top:40px;
    z-index:1;
    img {
      height:50px;
      cursor:pointer;
      margin-right:30px;
    }
  }
  .login_wrap {
    display:flex;
    width:1250px;
    position: absolute;
    top:50%;
    left:50%;
    margin-top: -240px;
    margin-left: -625px;
    .banner {
      width:660px;
      height:509px;
      background:url('~@/assets/img/pic/login.png');
      margin-right:120px;
    }
    .login_form {
      width:420px;
      .logo {
        text-align: center;
        > img {
          margin-left:-20px;
        }
      }
      :deep(.content_wrap) {
        background:#fff;
        border-radius:10px;
        padding:20px;
        h3 {
          font-weight: normal;
          color: #333;
          position: relative;
          font-size:18px;
          .el-button {
            min-height: auto;
            padding:0;
          }
        }
        .el-input {
          font-size:14px;
          background:#f3f3f3;
          border:none;
          height:45px;
          line-height:45px;
          border-radius: 4px;
          .el-input__inner {
            background:#f3f3f3;
            border:none;
          }
        }
        .input_tel {
          .el-input__inner {
            padding-left:90px;
          }
          .el-input__prefix {
            font-size:14px;
            color:#666;
            padding-left:10px;
            padding-right:10px;
            &:after {
              content: '';
              top:13px;
              width:2px;
              height:18px;
              background:#e6e6e6;
              position:absolute;
              right:0;
            }
          }
        }
        .btns {
          .el-button {
            min-height: 45px;
          }
          .el-button--primary {
            box-shadow:0 4px 15px rgba(0, 173, 239, 0.2);
          }
          .el-checkbox__label, .el-button--text {
            font-size:12px;
          }
        }
        .sep {
          position:relative;
          text-align:center;
          margin-top:-10px;
          margin-bottom:10px;
          > span {
            padding: 5px 15px;
            background:#fff;
            position: relative;
          }
          &:before {
            content: '';
            position:absolute;
            height:0;
            border-top:1px dashed #c0c4cc;
            top:8px;
            left:0;
            right:0;
          }
        }
        .third_login {
          position: relative;
          text-align: center;
          .el-button {
            margin-left:10px;
            margin-right:20px;
            font-size:40px;
          }
        }
      }
    }
  }
  .get_yzm {
    position:relative;
    .el-button, > span {
      position:absolute;
      right:10px;
      top:2px;
      font-size:12px;
    }
  }
  :deep(.forget_pwd_modal) {
    .el-input {
      line-height:45px;
      .el-input__inner {
        height:45px;
        line-height:45px;
      }
    }
  }
  :deep(.input_tel) {
    .el-input__inner {
      padding-left:90px;
    }
    .el-input__prefix {
      color:#666;
      padding-left:10px;
      padding-right:10px;
      &:after {
        content: '';
        top:13px;
        width:2px;
        height:18px;
        background:#e6e6e6;
        position:absolute;
        right:0;
      }
    }
  }
  .slide-fade-enter-active,
  .slide-fade-leave-active {
    transition: all 0.5s ease;
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateX(-100px);
    opacity: 0;
  }
  @media screen and (max-width: 1250px) {
    .login_wrap {
      left:0;
      margin-left:0;
    }
  }
  @media screen and (max-height: 680px) {
    .login_wrap {
      top:120px;
      margin-top:0;
    }
  }
</style>
